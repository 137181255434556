@font-face {
  font-family: CentryGothic;
  src: url(./Assets/Century\ Gothic.ttf);
}

.loading {
  background-color: #f2f2f2;
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
}
.gearIcon {
  transition: 0.5s all ease-in-out;
}
.gearIcon:hover {
  color: #284c8b;
}

.ant-input-search-button {
  height: 31px !important;
}

.anticon-search {
  margin-bottom: 5px !important;
}

.responsive-card {
  min-height: 230px;
}

* {
  font-family: CentryGothic;
  font-weight: 600;
}

.ant-typography {
  font-family: CentryGothic;
}

body {
  font-family: CentryGothic;
}

.bg-custom {
  background-image: url("./Assets/Polygon\ Luminary.svg");
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
}

.bg-custom-hexagon {
  background-image: url("./Assets/Hexagon.svg");
  background-repeat: no-repeat;
  background-size: cover;
}

.bg-custom-second {
  background-image: url("./Assets/cool-background.svg");
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
}

.shadow-md {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.bg-custom-second-grey {
  background-image: url("./Assets/Simple-Shiny-grey.svg");
  background-repeat: no-repeat;
  background-size: cover;
}

.bg-custom-waves {
  background-image: url("./Assets/Wave.svg");
  background-repeat: no-repeat;
  background-size: cover;
}

.border-right-custom {
  border-right: 1px solid gray;
}

.border-left-custom {
  border-left: 1px solid gray;
}

.bg-custom-shapes-1 {
  background: url("./Assets/Polygon\ Luminary.svg");
  background-repeat: no-repeat;
  background-size: cover;
}
.bg-custom-shapes-2 {
  background: url("./Assets/Curve\ Line.svg");
  background-repeat: no-repeat;
  background-size: cover;
}
.bg-custom-shapes-3 {
  background: url("./Assets/Stacked\ Wave.svg");
  background-repeat: no-repeat;
  background-size: cover;
}

button {
  position: relative;
  display: inline-block;
  cursor: pointer;
  outline: none;
  border: 0;
  vertical-align: middle;
  text-decoration: none;
  background: transparent;
  padding: 0;
  font-size: inherit;
  font-family: inherit;
}

button.learn-more {
  height: auto;
}

button.learn-more .circle {
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  position: relative;
  display: block;
  margin: 0;
  width: 3rem;
  height: 3rem;
  background: #284c8b;
  border-radius: 1.625rem;
}

.responsive-border {
  background-color: #f4f5f7;
  padding: 10px;
  border-radius: 13px;
}

button.learn-more .circle .icon {
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  background: white;
}

button.learn-more .circle .icon.arrow {
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  left: 0.625rem;
  width: 1.125rem;
  height: 0.125rem;
  background: none;
}

button.learn-more .circle .icon.arrow::before {
  position: absolute;
  content: "";
  top: -0.29rem;
  right: 0.0625rem;
  width: 0.625rem;
  height: 0.625rem;
  border-top: 0.125rem solid white;
  border-right: 0.125rem solid white;
  transform: rotate(45deg);
}

button.learn-more .button-text {
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 0.75rem 0;
  margin: 0 0 0 1.85rem;
  color: #001529;
  font-weight: 700;
  line-height: 1.6;
  text-align: center;
  text-transform: uppercase;
}

button:hover .circle {
  width: 100%;
}

button:hover .circle .icon.arrow {
  background: white;
  transform: translate(1rem, 0);
}

button:hover .button-text {
  color: white;
}

/* DISABLED CUSTOM BUTTON */

button.learn-more-disabled {
  height: auto;
}

button.learn-more-disabled .circle-disabled {
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  position: relative;
  display: block;
  margin: 0;
  width: 3rem;
  height: 3rem;
  background: #284c8b;
  border-radius: 1.625rem;
}

button.learn-more-disabled .circle-disabled .icon-disabled {
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  background: white;
}

.shadow-sm {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

button.learn-more-disabled .circle-disabled .icon-disabled.arrow-disabled {
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  left: 0.625rem;
  width: 1.125rem;
  height: 0.125rem;
  background: none;
}

button.learn-more-disabled
  .circle-disabled
  .icon-disabled.arrow-disabled::before {
  position: absolute;
  content: "";
  top: -0.29rem;
  right: 0.0625rem;
  width: 0.625rem;
  height: 0.625rem;
  border-top: 0.125rem solid white;
  border-right: 0.125rem solid white;
  transform: rotate(45deg);
}

button.learn-more-disabled .button-text-disabled {
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 0.75rem 0;
  margin: 0 0 0 1.85rem;
  color: #001529;
  font-weight: 700;
  line-height: 1.6;
  text-align: center;
  text-transform: uppercase;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
  /* display: none; */
  height: 5px;
}

/* Track */

/* Handle */
::-webkit-scrollbar-thumb {
  background: #a6adb4;
  border-radius: 40px;
  /* display: none; */
}

.bg-light {
  background-color: #f2f5fb !important;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #343a40;
  border-radius: 40px;
}

.ant-menu.ant-menu-inline-collapsed {
  width: 60px;
}

.ant-image-mask {
  border-radius: 50%;
}

.errorPageBackground {
  background-image: url("./Assets/CurveLineError.svg");
  background-repeat: no-repeat;
  background-size: cover;
}

.ant-menu-inline {
  width: auto;
}

.dot-spinner {
  --uib-size: 2.8rem;
  --uib-speed: 0.9s;
  --uib-color: #183153;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: var(--uib-size);
  width: var(--uib-size);
}

.dot-spinner__dot {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
  width: 100%;
}

.dot-spinner__dot::before {
  content: "";
  height: 20%;
  width: 20%;
  border-radius: 50%;
  background-color: var(--uib-color);
  transform: scale(0);
  opacity: 0.5;
  animation: pulse0112 calc(var(--uib-speed) * 1.111) ease-in-out infinite;
  box-shadow: 0 0 20px rgba(18, 31, 53, 0.3);
}

.dot-spinner__dot:nth-child(2) {
  transform: rotate(45deg);
}

.dot-spinner__dot:nth-child(2)::before {
  animation-delay: calc(var(--uib-speed) * -0.875);
}

.dot-spinner__dot:nth-child(3) {
  transform: rotate(90deg);
}

.dot-spinner__dot:nth-child(3)::before {
  animation-delay: calc(var(--uib-speed) * -0.75);
}

.dot-spinner__dot:nth-child(4) {
  transform: rotate(135deg);
}

.dot-spinner__dot:nth-child(4)::before {
  animation-delay: calc(var(--uib-speed) * -0.625);
}

.dot-spinner__dot:nth-child(5) {
  transform: rotate(180deg);
}

.dot-spinner__dot:nth-child(5)::before {
  animation-delay: calc(var(--uib-speed) * -0.5);
}

.dot-spinner__dot:nth-child(6) {
  transform: rotate(225deg);
}

.dot-spinner__dot:nth-child(6)::before {
  animation-delay: calc(var(--uib-speed) * -0.375);
}

.dot-spinner__dot:nth-child(7) {
  transform: rotate(270deg);
}

.dot-spinner__dot:nth-child(7)::before {
  animation-delay: calc(var(--uib-speed) * -0.25);
}

.dot-spinner__dot:nth-child(8) {
  transform: rotate(315deg);
}

.dot-spinner__dot:nth-child(8)::before {
  animation-delay: calc(var(--uib-speed) * -0.125);
}

@keyframes pulse0112 {
  0%,
  100% {
    transform: scale(0);
    opacity: 0.5;
  }

  50% {
    transform: scale(1);
    opacity: 1;
  }
}

.lead-custom {
  font-size: 18px;
  text-align: left;
}

.vote {
  cursor: pointer;
  width: 24px;
  border-radius: 13px;
  transition: 0.3s all ease-in-out;
}

.vote svg {
  display: inline;
  transition: 0.3s all ease-in-out;
}

.vote:hover svg path {
  fill: #284c8b;
  stroke: #284c8b;
}

.custom-margin-left {
  margin-left: 15px;
}

.custom-margin-right {
  margin-right: 15px;
}

@media screen and (max-width: 992px) {
  .lead-custom {
    font-size: 18px;
    text-align: left;
  }
  .text-md {
    font-size: 20px;
  }
  .responsive-card {
    min-height: 220px;
  }
}

@media screen and (max-width: 768px) {
  .lead-custom {
    font-size: 16px;
    text-align: left;
  }
  .text-md {
    font-size: 18px;
  }

  .responsive-card {
    min-height: 170px;
  }
  .custom-margin-right {
    margin-right: 0;
  }
  .custom-margin-left {
    margin-left: 0;
  }
}
.custum-btn-link {
  width: 183px;
  height: 32px;

  border-radius: 6px;
  padding: 5px, 19px, 5px, 19px;
  background-color: #95ceff !important;
}
.custum-text-upload {
  font-weight: 400;
  line-height: 25.14px;
  font-size: 13px;
  color: #303030;
}
.custum-card-post {
  width: 100%;
}
.datePoste {
  font-size: 12px;
  color: #284c8b;
}
.custum-display-link {
  color: #1f97ff;
  font-size: 12px;
  line-height: 18px;
  font-weight: 700;
}
.InboxColorCustum {
  color: #0e3170 !important ;
}
.custum-container-border {
  border: 1px solid #d9d9d9;
  border-radius: 6px;
}
.custem-border {
  border: 1px solid #2db7f5;
  border-radius: 10px;
}

.progress-bar-custom {
  background-color: #d7d7d8;
  height: 42px;
  border-radius: 100px;
  margin: 0.4rem 0;
  position: relative;
}

.check_Option_Sondage {
  margin: 1px;
}

.ant-picker-calendar
  .ant-picker-panel
  .ant-picker-content
  tbody
  tr
  td:nth-child(7) {
  display: none !important;
}

.ant-select-selection-item {
  font-weight: 600 !important;
}

p {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
@media screen and (max-width: 574px) {
  .collaps-sider {
    position: absolute !important;
    z-index: 100 !important;
  }

  .btn-collaps {
    position: absolute !important;
    z-index: 101 !important;
  }

  .lead-custom {
    font-size: 15px;
    text-align: left;
  }
  .text-md {
    font-size: 16px;
  }
  .responsive-card {
    min-height: 100px;
  }
  .custum-margi .my-calendar table thead tr > th:nth-child(6)::after {
    content: "i";
  }

  .my-calendar table thead tr > th:nth-child(7)::after {
    content: "t";
  }
  n-top {
    margin-bottom: 10px;
  }
}
