input[type="file"] {
    border:none;
    background-color: #F1F1F1;
    border-radius: 13px;
    cursor: pointer;
    margin-bottom: 10px;
}

input[type="file"]::file-selector-button {
    border: none;
    background-color: #284c8bc4;
    color: white;
    border-top-left-radius: 13px;
    border-bottom-left-radius: 13px;
    padding: .5rem;
}
